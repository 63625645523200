import React, { useEffect, useState } from "react";
import "./Content.css";

const Content = (props) => {
  const handleRedirect = (url) => {
    window.location.href = url;
  };
  return (
    <div className="flex justify-center items-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-4  sm:gap-12 gap-y-6  md:gap-y-4  mt-10  ">
        {props.data && props.data.posts && props.data.posts.length > 0 ? (
          props.data.posts.map((product, index) => (
            <div
              key={index}
              className="border w-64 h-56 sm:w-40 sm:h-40 md:w-44  md:h-44 lg:w-64 lg:h-64 overflow-hidden relative cursor-pointer rounded-lg moveUp "
              onClick={() => handleRedirect(product.link)}
            >
              <img
                key={index}
                src={product.imageUrl}
                width={300}
                height={200}
                alt={`Product ${index} - Image ${index + 1}`}
                className="mb-2"
              />
              <div className="bg-slate-800 absolute w-full bottom-0 h-16">
                <p className="text-3xl text-center ultra font-bold bg-white text-transparent bg-clip-text">
                  {product.title}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No posts available</p>
        )}
      </div>
    </div>
  );
};

export default Content;
