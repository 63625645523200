import React, { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

const App = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const res = await fetch(
        "https://pubtoysservercode.onrender.com/api/v1/posts"
      );
      const toyData = await res.json();
      setData(toyData);
    } catch (error) {
      console.log("This is the error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="">
      <Outlet context={{ postData: data }} /> {/* Renders the matched route */}
    </div>
  );
};

export default App;
