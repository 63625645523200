import React from "react";
import Header from "./Header";
import Content from "./Content";
import { useOutletContext } from "react-router-dom";

const Home = () => {
  const { postData } = useOutletContext();
  console.log("this is coming from home  ", postData);

  return (
    <div className="text-4xl bg-[#081c54] h-auto">
      <Header />
      <Content data={postData} />
    </div>
  );
};

export default Home;
