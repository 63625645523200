import React from "react";
import logo from "./../assets/instagram.svg";

const Header = () => {
  return (
    <div className="">
      <div className="flex sm:justify-between md:justify-between lg:justify-around  lg:flex-row md:flex-row sm:flex-row flex-col items-center  p-8 ">
        <div className="flex items-center gap-1">
          <img src={logo} className="lg:w-10 md:w-10 sm:w-8 w-6" />
          <p className="text-white ultra">Instagram</p>
        </div>
        <div className="ultra text-white text-4xl sm:text-5xl md:text-5xl  lg:text-5xl">
          Pubtoys
        </div>
      </div>
      <div className="border-b w-[90%] m-auto"></div>
    </div>
  );
};

export default Header;
