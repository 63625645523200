import React, { useState } from "react";

const Dashboard = () => {
  const [formData, setFormData] = useState({
    title: "",
    imageUrl: "",
    link: "", // made changes
  });
  const [imageFile, setImageFile] = useState(null); // State for the image file

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const imageChangeHandler = (e) => {
    setImageFile(e.target.files[0]); // Set the selected image file in state
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Add current date to formData
    const updatedFormData = { ...formData };

    // Step 1: Upload the image to Cloudinary using your image upload API
    if (imageFile) {
      const formDataWithImage = new FormData();
      formDataWithImage.append("imageFile", imageFile); // Use "imageFile" as the key

      try {
        const imageResponse = await fetch(
          "https://pubtoysservercode.onrender.com/api/v1/imageUpload", // Your image upload API
          {
            method: "POST",
            body: formDataWithImage, // Send FormData with image
            headers: {
              "Access-Control-Allow-Origin":
                "https://pubtoysservercode.onrender.com", // Allow CORS for local development
            },
          }
        );

        if (imageResponse.ok) {
          const imageResult = await imageResponse.json();
          console.log("Image uploaded successfully:", imageResult.imageUrl);

          // Step 2: Send only the text data to MongoDB (without the image URL)
          const formDataToMongo = {
            title: formData.title,
            imageUrl: imageResult.imageUrl,
            link: formData.link,
          };

          const mongoResponse = await fetch(
            "https://pubtoysservercode.onrender.com/api/v1/posts/create", // Your MongoDB API
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin":
                  "https://pubtoysservercode.onrender.com",
              },
              body: JSON.stringify(formDataToMongo), // Send only the form data (no image URL)
            }
          );

          if (mongoResponse.ok) {
            alert("Text data sent to MongoDB successfully");
            setFormData({
              title: "",
              imageUrl: "",
              link: "",
            });
          } else {
            alert("Failed to send text data to MongoDB");
          }
        } else {
          alert("Image upload failed");
        }
      } catch (e) {
        console.error("Error:", e);
        alert("An error occurred during the image upload");
      }
    } else {
      alert("Please select an image");
    }
  };

  return (
    <div className="">
      <section className="h-screen ">
        <h1 className="text-center text-3xl clash-font2">Pubtoys Dashboard</h1>
        <div className="flex justify-center">
          <form
            className="flex flex-col w-[50%] gap-5"
            onSubmit={submitHandler}
          >
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              name="title"
              className="border border-black text-black rounded-md h-10"
              value={formData.title}
              onChange={changeHandler}
            />
            <label htmlFor="link">Link:</label>
            <input
              type="text"
              id="link"
              name="link"
              className="border border-black text-black rounded-md h-10"
              value={formData.link}
              onChange={changeHandler}
            />

            <label htmlFor="image">Image:</label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={imageChangeHandler} // File input change handler
            />

            <button className="border bg-[#e5f926]">Post</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
